/* You can add global styles to this file, and also import other style files */

@font-face {
    font-family: 'Porsche Next';
    src: url('assets/font/PorscheNext-Regular.otf') format('opentype');
    /* src: url('assets/font/PorscheNext-SemiBold.otf') format('opentype'); */
    font-weight: 500;
    font-style: normal;
}

.ui-progress-spinner {
    position: relative;
    left: 40%
}

a.btn:not([href]):not([tabindex]):focus,
a.btn:not([href]):not([tabindex]):hover,
a.btn:not([href]):not([tabindex]) {
    color: white;
}

body {
    font-family: 'Porsche Next';
    background-color: #f7f5f5;
}

body .ui-listbox {
    width: 100%;
}

body .ui-multiselect-panel .ui-multiselect-header .ui-multiselect-filter-container {
    float: none;
    margin-left: 15px;
    padding-left: 25px;
}

body .ui-calendar.ui-calendar-w-btn .ui-datepicker-trigger.ui-button {
    position: relative;
    display: inline-block;
    font-size: 1rem;
}

app-dropdown {
    display: block;
}

.p-component {
    font-family: 'Porsche Next';
}

.p-datatable .p-datatable-thead > tr > th {
    color: #495057;
    background-color: #e9ecef;
    border-color: #dee2e6;
    border: 0px none;
    border-top: 1px solid #dee2e6;
}

.p-datatable .p-datatable-tbody > tr > td {    
    border: 0px none;
    border-top: 1px solid #dee2e6;        
}

.p-paginator {
    background-color: #e9ecef;
    color: #495057;
    border: 0px none;
    border-top: 1px solid #dee2e6;
}

.p-dropdown {    
    border-radius: 15px;
}

.p-dropdown-panel {
    border: 0px none;
    border-radius: 15px;
}

p-radiobutton.ng-valid,
p-inputswitch.ng-valid,
p-slider.ng-valid,
p-checkbox.ng-valid,
p-radiobutton.ng-invalid,
p-inputswitch.ng-invalid,
p-slider.ng-invalid,
p-checkbox.ng-invalid {
    border-left: 0px;
}

p-slider,
.ui-slider {
    margin-left: 6px;
}

p-calendar.form-control {
    padding: 0px;
    border: 0px;
}

body .ui-multiselect,
body .ui-dropdown {
    border-color: #ced4da;
}

body .ui-multiselect:hover:not(.ui-state-error),
body .ui-dropdown:hover:not(.ui-state-error) {
    border-color: #a6a6a6;
}

body .ui-inputtext:enabled:hover:not(.ui-state-error) {
    border-color: #a6a6a6;
}

body .ui-multiselect .ui-multiselect-label,
body .ui-dropdown .ui-dropdown-label {
    color: dimgrey;
}

.dealerCodeText {
    color: dimgrey;
    border-color: #ced4da;
    width: 133px;
    border: 1px solid #a6a6a6;
    font-size: 14px;
    height: 90%;
}

/* input[type="text" i] {
    border-radius: 3px;
} */

/* .reportTable span {
    display: none;
}

.reportTable data {
    color: blue;
    text-decoration: underline;
    cursor:pointer;
} */

.navbar-primary.collapsed .main-content .app-footer {
    margin-left: 0px;
}

.collapsed .main-content .app-footer {
    margin-left: 0px;
}

.main {
    padding-top: 95px;
    padding-left: 10px;
    background-color: white;
}

.main-content,
.app-footer {
    margin-left: 260px;
    /* padding: 10px;     */
    position: relative;
    /* min-height: 250px; */
}

.btn-primary {
    background-color: #26a69a !important;
    border-color: #26a69a !important;
}

.app-footer {
    background-color: cadetblue;
    color: #f5f5f5;
    display: block;
    overflow: hidden;
    padding-top: 2rem;
    flex: 0 0 50px;
    min-height: 46px;
    padding: 0 1rem;
    transition-duration: 0.25s, 0.25s;
    transition-property: margin-left, margin-right;
    font-weight: normal;
    font-size: 14px;
    position: relative;
}

.copyright-notice {
    width: 100%;
}

/* .right{
    float: right !important;
  } */

.grey-text.text-lighten-4 {
    color: #f5f5f5 !important;
}

.collapsed footer.app-footer {
    margin-left: 0px;
}

@media (min-width: 1001px) {
    .main {
        min-height: 933px !important;
    }
}

@media (max-width: 1000px) and (min-width: 700px) {
    .main {
        min-height: 730px;
    }
}

@media (max-width: 699px) and (min-width: 520px) {
    .main {
        min-height: 684px !important;
    }
}

@media (max-width: 1150px) and (min-width: 700px) {
    .main {
        min-height: 691px !important;
    }
}

/* @media (max-width: 1917px) and (min-width: 1150px) and (min-height: 917px) {
    .main {
        min-height: 691px !important;
    }
} */

@media (max-width: 1917px) and (min-width: 1150px) and (min-height: 737px) {
    .main {
        min-height: 912px !important;
    }
}

@media (min-width: 576px) {
    .col-sm-2 {
        flex: 0 0 20.666667%;
        max-width: 20.666667%;
    }
}

.report-form label {
    clear: both;
    margin-bottom: .2rem;
    font-size: 14px;
}

.report-form .report-button-container {
    align-self: flex-end;
    display: flex;
}

.report-form .form-group {
    box-sizing: border-box;
}

.report-center h2 {
    padding-top: 1rem;
}

h1,
h2,
h3,
h4,
h5 {
    margin: 0 0 12px 0;
}

h2 {
    font-size: 30px;
}

table.dataTable.display tbody tr.even {
    background-color: #ffffff;
}

table.dataTable.display tbody tr.odd {
    background: rgba(0, 0, 0, .05);
}

table.dataTable.display tbody tr.even .sorting_1 {
    background-color: #E2E4FF;
}

table.dataTable.display tr td {
    border: 1px solid #e1e6ef;
    color: #374767;
    font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    line-height: 1.5;
    font-size: 14px;
}

table.dataTable.display thead tr th {
    text-align: left;
    background: #b3dfff;
    border: 1px solid rgb(225, 230, 239);
    font-family: "TitlingGothicFB StandardComp", Oswald, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #374767;
    line-height: 1.5;
}

table.dataTable.display tfoot tr th {
    text-align: left;
    background: #b3dfff;
    font-family: "TitlingGothicFB StandardComp", Oswald, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #374767;
    line-height: 1.5;
}

.reportTable .drilldown {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
}

.uniform-bottom-margin {
    margin-bottom: 20px;
}

.infoModal {
    color: white;
    font-size: 1.93em;
    cursor: pointer;
    position: absolute;
    right: 100px;
    top: 10px;
}

.row {
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
}

.app-body {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    overflow-x: hidden;
}

.sidebar-fixed .sidebar {
    position: fixed;
    height: 100%;
}

.app-body .sidebar {
    flex: 0 0 250px;
    order: -1;
}

.sidebar {
    padding: 0;
    color: #fff;
    background: #004f91;
}

.sidebar-fixed .sidebar .sidebar-nav {
    height: calc(100vh - 60px);
}

.header_out {
    width: 100%;
    transition-duration: 0.25s, 0.25s;
    transition-property: margin-left, margin-right;
    flex: 0 0 60px;
    background-color: white;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%), 0 3px 1px -2px rgb(0 0 0 / 20%);
    height: 100px;
    padding: 0;
    border-bottom: 1px solid #e1e6ef;
    flex-direction: row;
    display: flex;
}

.navbar-text {
    font-size: 18px;
    font-weight: bold;
}

.col-sm-2 {
    padding-left: 0px;
    max-width: 17%;
    /* max-width: fit-content; */
}

.report_select {
    display: inline-block;
    border-width: 1px;
    cursor: pointer;
    height: 34px;
    background: #ffffff;
    border: 1px solid #a6a6a6;
    transition: border-color 0.2s;
    border-color: #ced4da;
    color: dimgrey;
    font-weight: normal;
    font-size: 15px;
}

.reportListing {
    cursor: pointer;
    font-size: 15px;
    font-weight: bold;
    text-decoration: underline !important;
    color: #004f91 !important;
}